import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"
import { Navigation, Pagination } from "swiper/js/swiper.esm"
import useMediaQuery from "../hooks/useMediaQuery"
import marked from "marked"

const ItConsulting = () => {
  const isDesktop = useMediaQuery("(min-width: 768px)")
  const params = {
    modules: [Pagination, Navigation],
    slidesPerView: 1,
    spaceBetween: 30,
    breakpoints: {
      280: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
    },
    containerClass: "swiper-container pb-5",
    nested: true,

    autoplay: false,
    // {
    //   delay: 5000,
    //   disableOnInteraction: false,
    // },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  }

  const data = [
    {
      title: "Low-Code Custom Application Developement",
      description:
        "<p>Get your team out of the spreadsheets and in front of customers with low-code applications that streamline operations, improve customer service, and provide clean data for AI-driven insights.</p>",
      url: "/quandary-build/",
      imgUrl: "/dceec435af07d66ca7a1aa259a5bdfc91ea427ff-1280x960.jpg",
      direction: "ltr",
    },
    {
      title: "Integrate and Automate Workflows",
      description:
        "<p>Eliminate manual data entry by connecting your disparate systems. Accelerate your ROI with reduced labor costs, fewer mistakes, and real-time data insights.</p>",
      url: "/quandary-connect/",
      imgUrl: "/d59e61ab873e78f8a1ee6e84ea00ce619c06e0c2-1280x959.jpg?w=570",
      direction: "rtl",
    },
  ]

  console.log("datasss", data)
  return (
    <section id="it-consulting" className="it-consulting">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-8 mx-auto text-center">
            <h2 className="my-2 font-weight-medium text-left-mobile">
              Enterprise-Level Solutions - Built in Weeks (Not Months)
            </h2>
            <p
              className="text-left-mobile mx-auto"
              style={{ maxWidth: "630px" }}
            >
              So you can connect your systems and automate processes with clean
              data by levering the latest AI enabled platforms.
            </p>
          </div>
        </div>
        {isDesktop ? (
          <LazyLoadComponent>
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 mt-4 mb-4">
                <StaticImage
                  placeholder="blurred"
                  className="rounded d-flex w-100"
                  src="https://cdn.sanity.io/images/55lcecww/production/dceec435af07d66ca7a1aa259a5bdfc91ea427ff-1280x960.jpg"
                  alt="Application Development"
                  layout="constrained"
                  style={{ height: "20rem" }}
                  loading="lazy"
                  fetchpriority="low"
                />
              </div>
              <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5 pb-5">
                <h3 className="text-left-mobile">
                  Low-Code Custom Application Developement
                </h3>
                <p className="my-4 text-left-mobile">
                  Get your team out of the spreadsheets and in front of
                  customers with low-code applications that streamline
                  operations, improve customer service, and provide clean data
                  for AI-driven insights.
                </p>

                <Link
                  className="btn btn-primary btn-arrow"
                  to="/quandary-build/"
                >
                  Explore Quandary Build
                  <span className="d-none">
                    This is a redirection to quandary-build
                  </span>
                </Link>
              </div>
            </div>

            <div
              className="row align-items-center flex-column-reverse flex-lg-row flex-md-row"
              style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
            >
              <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-5 text-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                <h3 className="text-left-mobile">
                  Integrate and Automate Workflows
                </h3>
                <p className="my-4 text-left-mobile">
                  Enhance AI insights with data orchestration that enables
                  informed decision-making (in real-time) for your entire team.
                  From the frontline to management.
                </p>
                <Link
                  className="btn btn-primary btn-arrow"
                  to="/quandary-connect/"
                >
                  Explore Quandary Connect
                  <span className="d-none">
                    This is a redirection to quandary-connect
                  </span>
                </Link>
              </div>
              <div className="col-lg-6 col-md-6 my-lg-4 my-md-4 my-0 mt-lg-0 mt-md-0 mt-5">
                <StaticImage
                  placeholder="blurred"
                  className="rounded d-flex w-100"
                  src="https://cdn.sanity.io/images/55lcecww/production/d59e61ab873e78f8a1ee6e84ea00ce619c06e0c2-1280x959.jpg?w=570"
                  alt="System Integration"
                  layout="constrained"
                  style={{ height: "20rem" }}
                  loading="lazy"
                  fetchpriority="low"
                />
              </div>
            </div>
            {/* 
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 mt-4 mb-4">
                <StaticImage
                  placeholder="blurred"
                  className="rounded d-flex w-100"
                  src="https://cdn.sanity.io/images/55lcecww/production/e90ceb8caee9906854df62ee9bcf372a7e9e56ad-1280x788.jpg?w=570"
                  alt="Application Development"
                  layout="constrained"
                  style={{ height: "20rem" }}
                  loading="lazy"
                  fetchpriority="low"
                />
              </div>
              <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                <h3 className="text-left-mobile">
                  End-to-End Procurement Optimization
                </h3>
                <p className="my-4 text-left-mobile">
                  Looking to streamline invoicing? Want to manage your vendors
                  from one, simple platform? Eager to supercharge your entire
                  procurement process with automation? We transform your supply
                  chain into a strategic asset.
                </p>
                <Link
                  className="btn btn-primary btn-arrow"
                  to="/procurement-automation-services/"
                >
                  Explore Procurement Automation
                  <span className="d-none">
                    This is a redirection to Procurement Automation
                  </span>
                </Link>
              </div>
            </div> */}

            {/* <div
              className="row align-items-center flex-column-reverse flex-lg-row flex-md-row"
              style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
            >
              <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-5 text-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                <h3 className="text-left-mobile">
                  Offline Construction Management
                </h3>
                <p className="my-4 text-left-mobile">
                  Get custom construction management solutions that work
                  offline, so you can effortlessly manage your workforce. No
                  matter the job site. Don’t let WiFi access restrict your team.
                </p>
                <Link
                  className="btn btn-primary btn-arrow"
                  to="/offline-application-development-construction-management/"
                >
                  Explore Construction Management
                </Link>
              </div>
              <div className="col-lg-6 col-md-6 my-lg-4 my-md-4 my-0 mt-lg-0 mt-md-0 mt-5">
                <StaticImage
                  placeholder="blurred"
                  className="rounded d-flex w-100"
                  src="https://cdn.sanity.io/images/55lcecww/production/574c4615dd06ab10e6cb8d2bd0c9dea134d8a37e-2000x1333.jpg"
                  alt="Construction Management"
                  layout="constrained"
                  style={{ height: "20rem" }}
                  loading="lazy"
                  fetchpriority="low"
                />
              </div>
            </div> */}

            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 mt-4 mb-4">
                <StaticImage
                  placeholder="blurred"
                  className="rounded d-flex w-100"
                  src="https://cdn.sanity.io/images/55lcecww/production/a05d3486dd392b13a8c69d078aeb51fdd1b257c6-2000x1334.jpg"
                  alt="Citizen Development"
                  layout="constrained"
                  style={{ height: "20rem" }}
                  loading="lazy"
                  fetchpriority="low"
                />
              </div>
              <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                <h3 className="text-left-mobile">
                  Citizen Development Governance
                </h3>
                <p className="my-4 text-left-mobile">
                  We help organizations stand up and govern citizen development
                  programs encouraging safe, scalable innovation. Accomplish
                  more. Risk less.
                </p>
                <Link
                  className="btn btn-primary btn-arrow"
                  to="/citizen-development-support/
              "
                >
                  Explore Citizen Development Governance
                </Link>
              </div>
            </div>

            <div
              className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-5 mb-5"
              style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
            >
              <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-5 text-center pl-md-5">
                <h3 className="text-left-mobile">
                  Business Process Improvement
                </h3>
                <p className="my-4 text-left-mobile">
                  Fix broken systems and remove the manual processes to increase
                  your team's productivity and eliminate unnecessary slowdowns
                  and bottlenecks with automated workflows.
                </p>
                <Link
                  className="btn btn-primary btn-arrow mb-lg-0 mb-md-4 mb-5"
                  to="/tech-consulting/"
                >
                  Explore Business Process Improvement
                </Link>
              </div>
              <div className="col-lg-6 col-md-6 mt-lg-4 mb-lg-4">
                <StaticImage
                  placeholder="blurred"
                  className="rounded d-flex"
                  src="https://cdn.sanity.io/images/55lcecww/production/d9df676a86e906bcc9f49e85cb19a6ecd89aa386-810x608.jpg"
                  alt="Solution Provider"
                  layout="constrained"
                  style={{ height: "20rem" }}
                  loading="lazy"
                  fetchpriority="low"
                />
              </div>
            </div>
          </LazyLoadComponent>
        ) : (
          <div className="mobile-swiper">
            <LazyLoadComponent>
              <Swiper {...params}>
                <div className="swiper-slide">
                  <div>
                    <div className="col-12">
                      {/* <StaticImage
                        placeholder="blurred"
                        className="rounded d-flex w-100"
                        src="https://cdn.sanity.io/images/55lcecww/production/6a384fbfcf147dea7e9056c75dae53153353f6e3-1280x960.jpg?w=570"
                        alt="Application Development"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      /> */}
                      <img
                        placeholder="blurred"
                        className="rounded d-flex w-100 img-fluid"
                        src="https://cdn.sanity.io/images/55lcecww/production/dceec435af07d66ca7a1aa259a5bdfc91ea427ff-1280x960.jpg"
                        alt="Application Development"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      />
                    </div>
                    <div className="col-12 text-center">
                      <h3 className="text-left-mobile">
                        Agile Business Solutions
                      </h3>
                      <p className="my-4 text-left-mobile">
                        Experience the speed to market that low code app
                        development provides. We fuse our industry experience
                        into the development and implementation of lean, custom
                        solutions.
                        <br />
                        <br />
                        Compete with Enterprise Organizations for a fraction of
                        the cost and go live faster than you thought possible.
                      </p>

                      <Link
                        className="btn btn-primary btn-arrow"
                        to="/quandary-build/"
                      >
                        Explore Quandary Build
                        <span className="d-none">
                          This is a redirection to quandary-build
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div>
                    <div className="col-12">
                      <StaticImage
                        placeholder="blurred"
                        className="rounded d-flex w-100"
                        src="https://cdn.sanity.io/images/55lcecww/production/d59e61ab873e78f8a1ee6e84ea00ce619c06e0c2-1280x959.jpg?w=570"
                        alt="System Integration"
                        layout="constrained"
                        style={{ height: "20rem" }}
                        loading="lazy"
                        fetchpriority="low"
                      />
                    </div>
                    <div className="col-12 text-center">
                      <h3 className="text-left-mobile">
                        Integrate and Automate Workflows
                      </h3>
                      <p className="my-4 text-left-mobile">
                        Eliminate manual data entry by connecting your disparate
                        systems. Accelerate your ROI with reduced labor costs,
                        fewer mistakes, and real-time data insights.
                      </p>
                      <Link
                        className="btn btn-primary btn-arrow"
                        to="/quandary-connect/"
                      >
                        Explore Quandary Connect
                        <span className="d-none">
                          This is a redirection to quandary-connect
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div>
                    <div className="col-12">
                      <img
                        placeholder="blurred"
                        className="rounded d-flex w-100"
                        src="https://cdn.sanity.io/images/55lcecww/production/e90ceb8caee9906854df62ee9bcf372a7e9e56ad-1280x788.jpg?w=570"
                        alt="Application Development"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      />
                    </div>
                    <div className="col-12 text-center">
                      <h3 className="text-left-mobile">
                        End-to-End Procurement Optimization
                      </h3>
                      <p className="my-4 text-left-mobile">
                        Looking to streamline invoicing? Want to manage your
                        vendors from one, simple platform? Eager to supercharge
                        your entire procurement process with automation? We
                        transform your supply chain into a strategic asset.
                      </p>
                      <Link
                        className="btn btn-primary btn-arrow"
                        to="/procurement-automation-services/"
                      >
                        Explore Procurement Automation
                        <span className="d-none">
                          This is a redirection to Procurement Automation
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div>
                    <div className="col-12">
                      <StaticImage
                        placeholder="blurred"
                        className="rounded d-flex w-100"
                        src="https://cdn.sanity.io/images/55lcecww/production/574c4615dd06ab10e6cb8d2bd0c9dea134d8a37e-2000x1333.jpg"
                        alt="Construction Management"
                        layout="constrained"
                        style={{ height: "20rem" }}
                        loading="lazy"
                        fetchpriority="low"
                      />
                    </div>
                    <div className="col-12 text-center">
                      <h3 className="text-left-mobile">
                        Offline Construction Management
                      </h3>
                      <p className="my-4 text-left-mobile">
                        Get custom construction management solutions that work
                        offline, so you can effortlessly manage your workforce.
                        No matter the job site. Don’t let WiFi access restrict
                        your team.
                      </p>
                      <Link
                        className="btn btn-primary btn-arrow"
                        to="/offline-application-development-construction-management/"
                      >
                        Explore Construction Management
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div>
                    <div className="col-12">
                      <img
                        placeholder="blurred"
                        className="rounded d-flex w-100"
                        src="https://cdn.sanity.io/images/55lcecww/production/a05d3486dd392b13a8c69d078aeb51fdd1b257c6-2000x1334.jpg"
                        alt="Citizen Development"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      />
                    </div>
                    <div className="col-12 text-center">
                      <h3 className="text-left-mobile">
                        Citizen Development Governance
                      </h3>
                      <p className="my-4 text-left-mobile">
                        We help organizations stand up and govern citizen
                        development programs encouraging safe, scalable
                        innovation. Accomplish more. Risk less.
                      </p>
                      <Link
                        className="btn btn-primary btn-arrow"
                        to="/citizen-development-support/
              "
                      >
                        Explore Citizen Development Governance
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div>
                    <div className="col-12">
                      <StaticImage
                        placeholder="blurred"
                        className="rounded d-flex"
                        src="https://cdn.sanity.io/images/55lcecww/production/d9df676a86e906bcc9f49e85cb19a6ecd89aa386-810x608.jpg"
                        alt="Solution Provider"
                        layout="constrained"
                        style={{ height: "20rem" }}
                        loading="lazy"
                        fetchpriority="low"
                      />
                    </div>
                    <div className="col-12 text-center">
                      <h3 className="text-left-mobile">
                        Business Process Improvement
                      </h3>
                      <p className="my-4 text-left-mobile">
                        Fix broken systems and remove the manual processes to
                        increase your team's productivity and eliminate
                        unnecessary slowdowns and bottlenecks with automated
                        workflows.
                      </p>
                      <Link
                        className="btn btn-primary btn-arrow mb-lg-0 mb-md-4 mb-5"
                        to="/tech-consulting/"
                      >
                        Explore Business Process Improvement
                      </Link>
                    </div>
                  </div>
                </div>
              </Swiper>
            </LazyLoadComponent>
          </div>
        )}
      </div>
    </section>
  )
}

export default ItConsulting
